'use strict';

(function (factory) {
    factory(jQuery);
}(function ($) {

    /*
     * Translated default messages for the jQuery validation plugin.
     * Locale: NL (Dutch; Nederlands, Vlaams)
     */
    $.extend($.validator.messages, {
        'invalid': 'Voor geldige gegevens in',
        'legalDate': 'Je moet minimaal 18 jaar oud zijn om in de Jack Wolfskin Shop te kunnen bestellen.',
        'invalidZip': "Voer een geldige postcode in",
        'invalidPassword': "Het wachtwoord moet minimaal een speciaal teken, een hoofdletter en een cijfer bevatten en minimaal 8 tekens lang zijn.",
        'passwordsDontMatch': "De wachtwoorden komen niet overeen.",
        'streetMissingHouseNumber': 'Je hebt geen huisnummer aangegeven, klopt dat?',
        'selectReturnReason': 'Kies een reden voor retourzending om verder te gaan'
    });
    return $;
}));
